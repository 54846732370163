import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const SpecialCategory = () => {
    const navigate = useNavigate()

    return (
        <div className="w-screen h-screen">
            <div className="bg-[#fbe600] text-[#000000] px-[2.5em] pt-[4em] pb-[0.5em] relative flex flex-col text-[#ffffff] justify-end">
                <div className="text-[30px] mt-[0.2em] absolute left-[8px] top-0 mb-auto"><AiOutlineArrowLeft onClick={() => navigate("/category")} /></div>
                <div className="text-[12px]">Senior High School</div>
                <div className="text-[25px] font-bold">
                    Specialized Subjects:
                </div>
            </div>
            <div className="flex flex-col px-[2.5em] mt-[1em]">
                <div className="py-[0.6em] text-[18px]" onClick={() => navigate("/specialized/world-religion")}>
                    Introduction of World Religions and Belief Systems
                </div>
                <div className="py-[0.6em] text-[18px]" onClick={() => navigate("/applied/general-mathematics")}>
                    Disciplines and Ideas in the Social Sciences
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Humanites 1: Creative Writing
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Disciplines and Ideas in the applied social sciences
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Humanities 2: Creative Nonfiction: The literary Essay
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Philippine Politics and Governance
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Work Immersion
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Trends, Networks and Critical Thinking in th 21st Century
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Community Engagement, Solidarity and Citizenship
                </div>
            </div>
        </div>
    )
}

export default SpecialCategory