import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const VideoLesson = () => {
    const vidRef = useRef()
    const navigate = useNavigate()
    const param = useParams()
    const [current, setcurrent] = useState()
    const [playindex, setplayindex] = useState()
    const [videourl, setvideourl] = useState()
    const [lesson, setlesson] = useState()

    const lessons = [
        {
            code: "4-3",
            video: [
                { title: "Introduction", file: "/assets/core-4-3/01.mp4" },
                { title: "Exponential Function Part 1", file: "/assets/core-4-3/02A.mp4" },
                { title: "Exponential Function Part 2", file: "/assets/core-4-3/02B.mp4" },
                { title: "Growth Rate", file: "/assets/core-4-3/03.mp4" },
                { title: "Compound Interest", file: "/assets/core-4-3/04.mp4" },
                { title: "Exponential Decay & Depreciation", file: "/assets/core-4-3/05.mp4" },
                { title: "Half-Life", file: "/assets/core-4-3/06.mp4" },
                { title: "Good Job!", file: "/assets/core-4-3/07.mp4" },
            ]
        },
        {
            code: "5-1",
            video: [
                { title: "watashiwa", file: "" },
                { title: "One Piece", file: "" },
            ]
        },
        {
            code: "5-2",
            video: [
                { title: "Lesson 1", file: "" },
                { title: "Lesson 2", file: "" },
            ]
        },
    ]

    useEffect(() => {
        let curr = []
        lessons.map(t => {
            if (t.code === param.code) curr = t
        })
        setcurrent(curr)
        setplayindex(0)
        let data = JSON.parse(localStorage.getItem("data"))
        console.log(data)
        setlesson(data)
    }, [param])

    useEffect(() => {
        if (current?.video?.length) {
            let url = `${process.env.PUBLIC_URL}${current?.video[playindex].file}`
            setvideourl(url)
        }
    }, [current, playindex])

    const onEnded = () => {
        let vidcount = current.video?.length || 0
        if (playindex + 1 < vidcount) {
            setplayindex(prev => prev + 1)
        }
    }

    const onPrev = () => {
        let vidcount = current.video?.length || 0
        if (playindex - 1 >= 0) {
            setplayindex(prev => prev - 1)
        }
    }

    const onNext = () => {
        let vidcount = current.video?.length || 0
        if (playindex + 1 < vidcount) {
            setplayindex(prev => prev + 1)
        }
    }

    return (
        (current) ? (
            <div className="w-screen h-screen flex flex-col">
                <div className="bg-[#d92626] px-[2.5em] relative pb-[0.5em] pt-[2em] flex flex-col text-[#ffffff] justify-end">
                    <div className="text-[30px] mt-[0.2em] absolute left-[8px] top-0 mb-auto"><AiOutlineArrowLeft onClick={() => navigate(`/${lesson.category}/${lesson.subject}`)} /></div>
                    <div className="text-[12px]">Lesson: {lesson.num}</div>
                    <div className="text-[15px] font-bold">
                        {lesson.lesson}
                    </div>
                </div>
                <div className="flex flex-col px-[2.5em] bg-[#1b1a1a] h-full">
                    <video
                        ref={vidRef}
                        autoPlay
                        className="bg-red w-full h-full"
                        controls={true}
                        onEnded={() => onEnded()}
                        src={videourl}
                    >
                    </video>
                </div>
                <div className="h-[200px] px-[2.5em] mt-auto flex flex-col p-[3px]">
                    <div className="w-full">{current.video?.length ? current.video[playindex].title : "No video file."}</div>
                    <div className="flex mt-auto mb-[1.2em]">
                        <div className={`flex items-center ${playindex === 0 ? "text-[#b1b0b0]" : ""}`}>
                            <BsArrowLeftCircle className="mr-[5px] text-[20px]" onClick={() => onPrev()} />
                            Prev
                        </div>
                        <div className={`flex items-center ml-auto ${playindex === current.video?.length - 1 ? "text-[#b1b0b0]" : ""}`}>
                            Next
                            <BsArrowRightCircle className="ml-[5px] text-[20px]" onClick={() => onNext()} />
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    )
}

export default VideoLesson