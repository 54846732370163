import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { AiOutlineArrowLeft } from 'react-icons/ai'

const SubjectPage = () => {
    const navigate = useNavigate()
    const param = useParams()
    const [current, setcurrent] = useState()

    const topics = [
        {
            subject: "general-mathematics",
            category: "core",
            pallete: "bg-[#d92626] text-[#ffffff]",
            title: "General Mathematics",
            data: [
                { 
                    chapter: 1, 
                    title: "Functions", 
                    lessons: [
                        "Lorem"
                    ] 
                },
                { 
                    chapter: 2, 
                    title: "Rational Functions", 
                    lessons: [
                        "Lorem"
                    ] 
                },
                { 
                    chapter: 3, 
                    title: "One-to-one and Inverse Functions", 
                    lessons: [
                        "Lorem"
                    ] 
                },
                { 
                    chapter: 4, 
                    title: "Exponential Functions", 
                    lessons: [
                        "Representing Real-Life Situations Using Exponential Functions",
                        "Exponential Functions, Equations, and Inequalities",
                        "Solving Real-Life Problems involving Exponential Functions, Equations and Inequalities",
                        "Graphing Exponential Functions"
                    ] 
                },
                {
                    chapter: 5, 
                    title: "Logarithmic Functions", 
                    lessons: [
                        "Introduction to Logarithms", 
                        "Logarithmic Functions, Equations, and Inequalities"
                    ]
                },
            ]
        },
        {
            subject: "oral-communication",
            category: "core",
            title: "Oral Communication",
            data: [
                { 
                    chapter: 1, 
                    title: "Oral 1", 
                    lessons: [
                        "Lorem"
                    ] 
                },
                { 
                    chapter: 2, 
                    title: "Oral 2", 
                    lessons: [
                        "Lorem"
                    ] 
                },
            ]
        },
        {
            subject: "test-subject",
            category: "applied",
            pallete: "bg-[#fbe600] text-[#000000]",
            title: "Introduction of World Religions and Belief System",
            data: [
                { 
                    chapter: 1, 
                    title: "Oral 1", 
                    lessons: [
                        "Introduction",
                        "bla bla bla"
                    ] 
                },
                { 
                    chapter: 2, 
                    title: "Oral 2", 
                    lessons: [
                        "Lorem"
                    ] 
                },
            ]
        },
        {
            subject: "world-religion",
            category: "specialized",
            pallete: "bg-[#0080f8] text-[#ffffff]",
            title: "Introduction of World Religions and Belief System",
            data: [
                { 
                    chapter: 1, 
                    title: "Oral 1", 
                    lessons: [
                        "Introduction",
                        "bla bla bla"
                    ] 
                },
                { 
                    chapter: 2, 
                    title: "Oral 2", 
                    lessons: [
                        "Lorem"
                    ] 
                },
            ]
        }
    ]

    useEffect(() => {
        let curr = []
        topics.map(t => {
            if (t.subject === param.subject) curr = t
        })
        setcurrent(curr)
    }, [param])

    const selectLesson = (i, d, curr) => {
        let data = {
            category: curr.category,
            code: `${d.chapter}-${i + 1}`,
            chapter: d.chapter,
            subject: curr.subject,
            title: d.title,
            num: i + 1,
            lesson: d.lessons[i],
        }
        localStorage.setItem("data", JSON.stringify(data))
        navigate(`/${curr.category}/${param.subject}/${d.chapter}-${i + 1}`)
    }

    const renderLessons = (d, curr) => {
        return (
            (d.lessons && d.lessons.length) ? (
                d.lessons.map((l, i) => (
                    <div key={l + i} className="ml-[1.5em]" onClick={() => selectLesson(i, d, curr)}>
                        <i>Lesson {i + 1}:</i> {l}
                    </div>
                ))
            ) : null
        )
    }

    return (
        (current) ? (
            <div className="w-screen h-screen">
                <div className={`${current.pallete} px-[2.5em] relative pt-[4em] pb-[0.5em] flex flex-col text-[#ffffff] justify-end`}>
                    <div className="text-[30px] mt-[0.2em] absolute left-[8px] top-0 mb-auto"><AiOutlineArrowLeft onClick={() => navigate(`/${current.category}`)} /></div>
                    <div className="text-[12px]">{current.category.toUpperCase()} Subject</div>
                    <div className="text-[25px] font-bold">
                        {current.title}
                    </div>
                </div>
                <div className="flex flex-col px-[2.5em] mt-[1em]">
                    {
                        (current.data && current.data.length) ? (
                            current.data.map((d, index) => (
                                <div key={d + index} className="py-[0.6em] text-[18px] flex flex-col">
                                    <div className="font-bold">
                                        Chapter {d.chapter}: {d.title}
                                    </div>
                                    {renderLessons(d, current)}
                                </div>
                            ))
                        ) : null
                    }
                </div>
            </div>
        ) : null
    )
}

export default SubjectPage