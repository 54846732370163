import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const InvalidPage = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className="text-[30px] mt-[0.2em] absolute left-[8px] top-0 mb-auto">
                <AiOutlineArrowLeft onClick={() => navigate("/category")} />
            </div>
            <div className="w-screen h-screen flex items-center justify-center text-[30px]">
                PAGE DOES NOT EXIST
            </div>
        </>
    )
}

export default InvalidPage