import './styles/output.css'
import './styles/index.css'
import { BrowserRouter } from 'react-router-dom'
import { MainRoutes } from './route/mainRoute'

function App() {
    return (
        <BrowserRouter>
            <MainRoutes />
        </BrowserRouter>
    )
}

export default App