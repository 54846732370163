import React from 'react'
import { useNavigate } from "react-router-dom"
import { AiOutlineArrowLeft } from 'react-icons/ai'

const SelectionPage = () => {
    const navigate = useNavigate()

    return (
        <div className="w-screen h-screen flex flex-col">
            <div className="text-[30px] mt-[0.2em] absolute left-[8px] top-0 mb-auto text-[#ffffff]"><AiOutlineArrowLeft onClick={() => navigate("/")} /></div>
            <div className="w-full h-full bg-[#d92626] p-[1em] font-bold text-[#ffffff] text-[25px] flex justify-center items-center cursor-pointer" onClick={() => navigate("/core")}>
                CORE SUBJECTS
            </div>
            <div className="w-full h-full bg-[#0080f8] p-[1em] font-bold text-[#ffffff] text-[25px] flex justify-center items-center cursor-pointer" onClick={() => navigate("/applied")}>
                APPLIED SUBJECTS
            </div>
            <div className="w-full h-full bg-[#fbe600] p-[1em] font-bold text-[#000000] text-[25px] flex justify-center items-center cursor-pointer" onClick={() => navigate("/specialized")}>
                SPECIALIZED SUBJECTS
            </div>
        </div>
    )
}

export default SelectionPage