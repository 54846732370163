import { Route, Routes } from "react-router-dom"
import AppliedCategory from "../pages/categApplied"
import CoreCategory from "../pages/categCore"
import SelectionPage from "../pages/selection"
import SpecialCategory from "../pages/categSpecial"
import HomePage from "../pages/home"
import InvalidPage from "../pages/invalid"
import SubjectPage from "../pages/subject"
import VideoLesson from "../pages/videoLesson"

export const MainRoutes = () => {
    return (
        <Routes>
            <Route path="/category" element={<SelectionPage />} />
            <Route path="/core" element={<CoreCategory />} />
            <Route path="/core/:subject" element={<SubjectPage />} />
            <Route path="/core/:subject/:code" element={<VideoLesson />} />
            <Route path="/applied" element={<AppliedCategory />} />
            <Route path="/applied/:subject" element={<SubjectPage />} />
            <Route path="/applied/:subject/:code" element={<VideoLesson />} />
            <Route path="/specialized" element={<SpecialCategory />} />
            <Route path="/specialized/:subject" element={<SubjectPage />} />
            <Route path="/specialized/:subject/:code" element={<VideoLesson />} />
            <Route index element={<HomePage />} />
            <Route path="*" element={<InvalidPage />} />
        </Routes >
    )
}