import React from 'react'
import { useNavigate } from "react-router-dom"
import logo from './../assets/getStarted.png'

const HomePage = () => {
    const navigate = useNavigate()
    const splash = "/assets/MainSplash.mp4"

    return (
        <>
            <div className="bg-[#000000] text-[#ffffff] w-full h-full absolute top-0 left-0 z-[-1] overflow-hidden">
                <video
                    autoPlay
                    src={`${process.env.PUBLIC_URL}${splash}`}
                >
                </video>
            </div>
            <div className="w-screen h-screen">
                <div className="flex flex-col h-full items-center justify-center">
                    <div className="flex justify-start mt-[1em] max-w-[300px] md:max-w-[500px] w-full">
                        <img src={logo} className="cursor-pointer" onClick={() => navigate("/category")} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePage