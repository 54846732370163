import React from 'react'
import { useNavigate } from "react-router-dom"
import { AiOutlineArrowLeft } from 'react-icons/ai'

const CoreCategory = () => {
    const navigate = useNavigate()

    return (
        <div className="w-screen h-screen">
            <div className="bg-[#d92626] px-[2.5em] pt-[4em] pb-[0.5em] relative flex flex-col text-[#ffffff] justify-end">
                <div className="text-[30px] mt-[0.2em] absolute left-[8px] top-0 mb-auto"><AiOutlineArrowLeft onClick={() => navigate("/category")} /></div>
                <div className="text-[12px]">Senior High School</div>
                <div className="text-[25px] font-bold">
                    Core Subjects:
                </div>
            </div>
            <div className="flex flex-col px-[2.5em] mt-[1em]">
                <div className="py-[0.6em] text-[18px]" onClick={() => navigate("/core/oral-communication")}>
                    Oral Communication
                </div>
                <div className="py-[0.6em] text-[18px]" onClick={() => navigate("/core/general-mathematics")}>
                    General Mathematics
                </div>
                <div className="py-[0.6em] text-[18px]">
                    21st Century Literature from the Philippines and the World
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Media Information Literacy
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Introduction to the Philosophy of the Human Person
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Physical Education and Health 1
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Reading and Writing
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Statistics and Probability
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Understanding Culture, Society and Politics
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Earth and Life Science
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Komunikasyon at Pananaliksik sa Wika at Kulturang Pilipino
                </div>
                <div className="py-[0.6em] text-[18px]">
                    Physical Education and Health 2
                </div>
            </div>
        </div>
    )
}

export default CoreCategory